import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { AuthSessionModel } from '@shared/models/users/auth-session-model';
import { IAuthService } from '@shared/types/auth-service';
import { IUsersService } from '@shared/types/users-service';

export class LoginWithQRCodeViewModel {
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private authService = appInject<IAuthService>(DI_TOKENS.authService);

  constructor() {}

  сreateAuthorizationRequest = async () => {
    return await this.usersService.сreateAuthorizationRequest();
  };

  getAuthorizationRequest = async (deviceCode: string | undefined) => {
    if (!deviceCode) return;
    return await this.usersService.getAuthorizationRequest(deviceCode);
  };

  authorizeUser = async (session: AuthSessionModel) => {
    return await this.authService.authorizeBySessionInfo(session);
  };
}
