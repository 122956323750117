import React, { KeyboardEvent } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { InputText } from '@shared/components/new-design/input-text';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';

import {
  IRecoveryPasswordForm,
  RecoveryPasswordFieldsResolver,
  RecoveryPasswordFields,
} from './password.validator';

import { styles } from './password.styles';

export interface PasswordFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (email: string) => void;
  error?: string;
  defaultValue?: string;
  resetValidation?: () => void;
  isLoading?: boolean;
}

const PasswordFormComponent: React.FC<PasswordFormProps> = ({
  onSubmit,
  defaultValue,
  classes,
  error,
  resetValidation,
  isLoading,
}) => {
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RecoveryPasswordFields>({ resolver: RecoveryPasswordFieldsResolver });

  const onSubmitFrom = ({ password }: IRecoveryPasswordForm) => {
    onSubmit(password);
  };

  const resetValidationForm = () => {
    resetValidation && resetValidation();
    setError(nameOf<IRecoveryPasswordForm>('password'), { message: '' });
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && isValid && !Boolean(errors.password?.message || error)) {
      handleSubmit(onSubmitFrom)();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFrom)} onKeyDown={handleKeyPress} className={classes.root}>
      <div>
        <div className={classes.title}>{t`Create a Password`}</div>
        <div className={classes.description}>
          {t`Password must consist of min 8 characters including at least one upper-case character, one number and one special character`}
        </div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <InputText
              controls={register(nameOf<IRecoveryPasswordForm>('password'))}
              placeholder={t`Min 8 characters`}
              resetValidation={resetValidationForm}
              autoFocus
              defaultValue={defaultValue}
              type={'password'}
              error={Boolean(errors.password?.message)}
              helperText={errors.password?.message}
              key={'password'}
            />
          </div>
        </div>
      </div>
      <SubmitButton
        label={t`Continue`}
        disabled={!isValid || Boolean(errors.password?.message || error)}
        isLoading={isLoading}
        onSubmit={handleSubmit(onSubmitFrom)}
      />
    </form>
  );
};

export default appWithStyles(styles)(PasswordFormComponent);
