import React, { MouseEvent, KeyboardEvent } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import {
  IRegisterEmailForm,
  RegisterEmailFieldsResolver,
} from '@modules/public/auth/register/forms/email/email.validator';
import { InputText } from '@shared/components/new-design/input-text';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';

import { styles } from './email.styles';

export interface RegisterEmailFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (email: string) => void;
  isAvailableRegistration: boolean;
  resetValidation?: () => void;
  isEmailExistsError: boolean;
  defaultValue: string;
  error?: string;
  isLoading?: boolean;
}

const EmailFormComponent: React.FC<RegisterEmailFormProps> = ({
  classes,
  error,
  onSubmit,
  resetValidation,
  isEmailExistsError,
  defaultValue,
  isLoading,
  isAvailableRegistration,
}) => {
  const navigate = useNavigate();

  const {
    setError,
    getValues,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<IRegisterEmailForm>({ resolver: RegisterEmailFieldsResolver });

  const onSubmitFrom = ({ email }: IRegisterEmailForm) => {
    onSubmit(email);
  };

  const redirectToSignIn = (
    e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLSpanElement>,
    email: string | undefined,
  ) => {
    e.preventDefault();
    navigate(ROUTES.public.login(email));
  };

  const resetValidationFunc = () => {
    setError(nameOf<IRegisterEmailForm>('email'), { message: '' });
    resetValidation && resetValidation();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && isValid && !Boolean(error)) {
      handleSubmit(onSubmitFrom)();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFrom)} onKeyDown={handleKeyPress} className={classes.root}>
      <div>
        <div className={classes.title}>{t`Create an account with Email`}</div>
        <div className={classes.description}>
          {t`Already have an account?`}{' '}
          <a
            href="#"
            className={classes.link}
            onClick={(e) => redirectToSignIn(e, undefined)}
          >{t`Sign In`}</a>
        </div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <InputText
              controls={register(nameOf<IRegisterEmailForm>('email'))}
              placeholder={t`Email`}
              resetValidation={resetValidationFunc}
              autoFocus
              defaultValue={defaultValue}
              error={Boolean(error)}
              helperText={error}
              key={'email'}
            />
          </div>
          {isEmailExistsError ? (
            <div className={classes.existsEmailError}>
              {t`It seems ${getValues().email} already exist. Would you like to`}{' '}
              <span
                className={classes.signInButton}
                onClick={(e) => redirectToSignIn(e, getValues().email)}
              >{t`Sign In`}</span>{' '}
              {t`with it?`}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <SubmitButton
        label={t`Sign Up`}
        disabled={!isValid || Boolean(error) || isEmailExistsError || !isAvailableRegistration}
        isLoading={isLoading}
        onSubmit={handleSubmit(onSubmitFrom)}
      />
    </form>
  );
};

export default appWithStyles(styles)(EmailFormComponent);
