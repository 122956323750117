import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { t } from '@lingui/macro';
import { IsEmail, IsString, MinLength } from 'class-validator';

export interface ILoginForm {
  email: string;
  password: string;
}

export class LoginFormFields implements ILoginForm {
  @MinLength(1, { message: t`This field is mandatory` })
  @IsEmail({}, { message: t`Wrong email format` })
  email: string;

  @IsString()
  @MinLength(1, { message: t`This field is mandatory` })
  password: string;
}

export const LoginFieldsResolver = classValidatorResolver(LoginFormFields);
