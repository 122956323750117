import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { t } from '@lingui/macro';
import { IsEmail, MinLength } from 'class-validator';

export interface IRecoveryEmailForm {
  email: string;
}

export class RecoveryEmailFields implements IRecoveryEmailForm {
  @MinLength(1, { message: t`This field is mandatory` })
  @IsEmail({}, { message: t`Wrong email format` })
  email: string;
}

export const RecoveryEmailFieldsResolver = classValidatorResolver(RecoveryEmailFields);
