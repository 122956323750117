import React, { useEffect } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Theme, useMediaQuery } from '@mui/material';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';

import DeleteAccountImage from './img/delete-account.svg';

import { styles } from './delete-accout.styles';

export interface DeleteAccountResultProps extends AppWithStyles<typeof styles> {}

const DeleteAccountResultComponent: React.FC<DeleteAccountResultProps> = ({ classes }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  useEffect(() => {
    if (!isMobile) {
      navigate(ROUTES.public.login());
    }
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.title}>{t`Delete account`}</div>
      <div className={classes.imageBlock}>
        <img className={classes.image} src={String(DeleteAccountImage)} />
      </div>
      <div className={classes.info}>
        <p>{t`Your profile has been permanently deleted.`}</p>
        <p>{t`Now, you can use your email and phone number to register a new user profile.`}</p>
      </div>
      <div
        className={classes.description}
      >{t`Click "OK" to leave WealthStack. We hope to see you back soon.`}</div>
      <div className={classes.buttonBlock}>
        <SubmitButton
          label={t`Ok`}
          disabled={false}
          isLoading={false}
          onSubmit={() => navigate(ROUTES.public.login())}
        />
      </div>
    </div>
  );
};

export default appWithStyles(styles)(DeleteAccountResultComponent);
