import { PasswordStrength } from '@core/forms/validators/password-strength';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Validate } from 'class-validator';

export interface IRegisterPasswordForm {
  password: string;
}

export class RegisterPasswordFields implements IRegisterPasswordForm {
  @Validate(PasswordStrength)
  password: string;
}

export const RegisterPasswordFieldsResolver = classValidatorResolver(RegisterPasswordFields);
