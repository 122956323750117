import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { AuthStatusData, IAuthService } from '@shared/types/auth-service';
import { IConfigService } from '@shared/types/config-service';
import { IUsersService } from '@shared/types/users-service';

export class LoginViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);

  get isAvailableRegistration() {
    return this.configService.isAvailableRegistration;
  }

  constructor() {}

  login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<AuthStatusData> => {
    return await this.authService.login(email, password);
  };

  sendCode = async (data: { code: string }) => {
    return await this.authService.loginWithOtp(data.code);
  };

  getUserInfo = async () => {
    if (this.authService.isLoggedIn) {
      return await this.usersService.getMyUserDetails();
    }
    return null;
  };

  get mfaDelayMinutes() {
    return this.configService.mfaDelayMinutes;
  }
}
