import React, { useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { OtpView } from '@shared/components/new-design/otp-view';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { useMutation } from 'react-query';

import EmailForm from './forms/email/email';
import PasswordForm from './forms/password/password';
import { RecoveryViewModel } from './password-recovery.vm';

import { styles } from './password-recovery.styles';

enum RecoveryFormEnum {
  EMAIL = 0,
  OTP = 1,
  PASSWORD = 2,
}

export interface RecoveryProps extends AppWithStyles<typeof styles> {}

const RecoveryComponent: React.FC<RecoveryProps> = ({ classes }) => {
  const navigate = useNavigate();

  const [activeForm, setActiveForm] = useState<RecoveryFormEnum>(RecoveryFormEnum.EMAIL);

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');

  const [lastSendCodeDate, setLastSendCodeDate] = useState<Date | null>(null);

  const $vm = React.useMemo(() => new RecoveryViewModel(), []);

  const requestCodeMutation = useMutation(
    (data: { email: string }) => $vm.requestCode(data.email),
    {
      onSuccess: (result: boolean) => {
        if (result) {
          setLastSendCodeDate(new Date());
          setActiveForm(RecoveryFormEnum.OTP);
        }
      },
      onError: () => {
        setOtpError(t`Something went wrong. Please try again later.`);
      },
    },
  );

  const sendCodeMutation = useMutation(
    (data: { code: string; password: string }) => $vm.sendCode(data.code, data.password),
    {
      onSuccess: (result: boolean) => {
        if (result) {
          navigate(ROUTES.public.login());
        } else {
          setOtpError(t`Invalid code.`);
        }
      },
      onError: () => {
        setOtpError(t`Invalid code`);
      },
    },
  );

  const isEmailExistsMutation = useMutation(
    (data: { email: string }) => $vm.isEmailExists(data.email),
    {
      onSuccess: (isExists) => {
        if (!isExists) {
          setEmailError(t`The email is incorrect, Please double check it`);
        } else {
          setActiveForm(RecoveryFormEnum.PASSWORD);
        }
      },
      onError: (error: HttpErrorResponse) => {
        setEmailError(error.findFirstErrorMessage());
      },
    },
  );

  const onSubmitEmail = (email: string) => {
    setEmail(email);
    isEmailExistsMutation.mutate({ email });
  };

  const onSubmitPassword = (password: string) => {
    setPassword(password);
    requestCodeMutation.mutate({ email });
  };

  const handleResendOtp = () => {
    setOtpError('');
    requestCodeMutation.mutate({ email });
  };

  const handleOtpSubmit = (code: string) => {
    sendCodeMutation.mutate({ code, password });
  };

  const resetValidationEmail = () => {
    setEmailError('');
  };

  const resetValidationPassword = () => {
    setPasswordError('');
  };

  const isLoading = () => {
    return (
      requestCodeMutation.isLoading || sendCodeMutation.isLoading || isEmailExistsMutation.isLoading
    );
  };

  const emailForm = (
    <EmailForm
      onSubmit={onSubmitEmail}
      error={emailError}
      defaultValue={email}
      resetValidation={resetValidationEmail}
      isLoading={isLoading()}
    />
  );

  const passwordForm = (
    <PasswordForm
      onSubmit={onSubmitPassword}
      error={passwordError}
      defaultValue={password}
      resetValidation={resetValidationPassword}
      isLoading={isLoading()}
    />
  );

  const otpForm = (
    <OtpView
      title={t`Enter code`}
      subTitle={t`Please verify with the 6-digits code sent you to`}
      email={email}
      timerDate={lastSendCodeDate as Date}
      timerDelayMinutes={$vm.mfaDelayMinutes}
      error={otpError}
      onResend={handleResendOtp}
      onBack={() => {}}
      onSubmit={handleOtpSubmit}
      isLoading={isLoading()}
    />
  );

  let formComponent = <></>;
  switch (activeForm) {
    case RecoveryFormEnum.EMAIL:
      formComponent = emailForm;
      break;
    case RecoveryFormEnum.PASSWORD:
      formComponent = passwordForm;
      break;
    case RecoveryFormEnum.OTP:
      formComponent = otpForm;
      break;
    default:
      formComponent = <></>;
  }

  const handleBackButton = () => {
    switch (activeForm) {
      case RecoveryFormEnum.EMAIL:
        navigate(ROUTES.public.login());
        break;
      case RecoveryFormEnum.PASSWORD:
        setActiveForm(RecoveryFormEnum.EMAIL);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.backButton}>
        {[RecoveryFormEnum.EMAIL, RecoveryFormEnum.PASSWORD].includes(activeForm) ? (
          <BackButton onClick={handleBackButton} />
        ) : (
          <></>
        )}
      </div>
      <div className={classes.form}>{formComponent}</div>
    </div>
  );
};

export default appWithStyles(styles)(RecoveryComponent);
