import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      '& svg': {
        borderRadius: '25px',
      },
    },
    blur: {
      filter: 'blur(5px)',
    },
  });
}
