import { appCreateStyles } from '@core/theme/utils/create-styles';
export function styles() {
  return appCreateStyles({
    root: {
      height: '100%',
    },
    form: {
      height: '100%',
    },
  });
}
