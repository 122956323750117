import React, { MouseEvent, KeyboardEvent } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { InputText } from '@shared/components/new-design/input-text';
import SubmitButton from '@shared/components/new-design/submit-button';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';

import { ILoginForm, LoginFieldsResolver, LoginFormFields } from './login-form.validator';

import { styles } from './login-form.styles';

export interface LoginFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (email: string, password: string) => void;
  isAvailableRegistration: boolean;
  resetValidation?: () => void;
  defaultEmail?: string;
  isLoading?: boolean;
  defaultPassword?: string;
  error?: string;
}

const LoginFormComponent: React.FC<LoginFormProps> = ({
  classes,
  error,
  onSubmit,
  resetValidation,
  defaultEmail,
  defaultPassword,
  isLoading,
  isAvailableRegistration,
}) => {
  const navigate = useNavigate();

  const {
    register,
    setError,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginFormFields>({ resolver: LoginFieldsResolver });

  const onSubmitFrom = ({ email, password }: ILoginForm) => {
    onSubmit(email, password);
  };

  const resetValidationEmail = () => {
    setError(nameOf<ILoginForm>('email'), {});
    setError(nameOf<ILoginForm>('password'), {});
    resetValidation && resetValidation();
  };

  const resetValidationPassword = () => {
    setError(nameOf<ILoginForm>('email'), {});
    setError(nameOf<ILoginForm>('password'), {});
    resetValidation && resetValidation();
  };

  const redirectToSignUp = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(ROUTES.public.register);
  };

  const redirectToForgotPassword = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(ROUTES.public.passwordRecovery);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && isValid) {
      handleSubmit(onSubmitFrom)();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFrom)} onKeyDown={handleKeyPress} className={classes.root}>
      <div>
        <div className={classes.title}>{t`Please Log in to your account`}</div>
        {isAvailableRegistration ? (
          <div className={classes.description}>
            {t`Don't have any account yet?`}{' '}
            <a href="#" className={classes.link} onClick={redirectToSignUp}>{t`Sign Up`}</a>
          </div>
        ) : (
          <></>
        )}
        <div className={classes.fields}>
          <div className={classes.field}>
            <InputText
              controls={register(nameOf<ILoginForm>('email'))}
              placeholder={t`Email`}
              resetValidation={resetValidationEmail}
              autoFocus
              defaultValue={defaultEmail || ''}
              key={'email'}
            />
          </div>
          <div className={classes.field}>
            <InputText
              controls={register(nameOf<ILoginForm>('password'))}
              placeholder={t`Password`}
              type={'password'}
              resetValidation={resetValidationPassword}
              autoFocus={false}
              error={Boolean(error)}
              helperText={error}
              defaultValue={defaultPassword || ''}
              key={'password'}
            />
          </div>
          <a
            href="#"
            className={classes.forgotPassword}
            onClick={redirectToForgotPassword}
          >{t`Forgot password?`}</a>
        </div>
      </div>
      <SubmitButton
        label={t`Sign In`}
        disabled={!isValid}
        isLoading={isLoading}
        onSubmit={handleSubmit(onSubmitFrom)}
      />
    </form>
  );
};

export default appWithStyles(styles)(LoginFormComponent);
