import React, { useMemo, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { OtpView } from '@shared/components/new-design/otp-view';
import { useNavigate, useLocation } from '@shared/components/router';
import { AuthStatus } from '@shared/constants/auth';
import { ROUTES } from '@shared/constants/routes';
import { UserStatus } from '@shared/models/users/user-status';
import { AuthStatusData } from '@shared/types/auth-service';
import { useMutation, useQuery } from 'react-query';

import LoginForm from './forms/login-form/login-form';
import { LoginViewModel } from './login.vm';

import { styles } from './login.styles';

enum LoginFormEnum {
  CREDENTIALS = 0,
  OTP = 1,
}

export interface LoginProps extends AppWithStyles<typeof styles> {}

const LoginComponent: React.FC<LoginProps> = ({ classes }) => {
  const [activeForm, setActiveForm] = useState<LoginFormEnum>(LoginFormEnum.CREDENTIALS);

  const navigate = useNavigate();

  const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQueryString();
  const userEmail = query.get('userEmail') || '';

  const [loginError, setLoginError] = useState<string>('');
  const [lastLoginTryDate, setLastLoginTryDate] = useState<Date | null>(null);

  const [email, setEmail] = useState<string>(userEmail || '');
  const [password, setPassword] = useState<string>('');

  const [otpError, setOtpError] = useState<string>('');

  const $vm = React.useMemo(() => new LoginViewModel(), []);

  const loginMutation = useMutation(
    (data: { email: string; password: string }) => $vm.login(data),
    {
      onSuccess: (result: AuthStatusData) => {
        switch (result.status) {
          case AuthStatus.loggedOut:
            setLoginError(t`Incorrect email or password`);
            if (result?.data?.message === t`User is disabled.`) {
              setLoginError(t`Your account has been blocked`);
            }
            break;
          case AuthStatus.mfaRequiredSMS:
            setLastLoginTryDate(new Date());
            setActiveForm(LoginFormEnum.OTP);
            break;
          case AuthStatus.registeredUnconfirmed:
          case AuthStatus.passwordChangeRequired:
            setLoginError(result?.data?.message || '');
            break;
          case AuthStatus.loggedIn:
            getUserInfoQuery.refetch();
            break;
          default:
            break;
        }
      },
    },
  );

  const sendCodeMutation = useMutation((data: { code: string }) => $vm.sendCode(data), {
    onSuccess: (result: AuthStatusData) => {
      switch (result.status) {
        case AuthStatus.mfaRequiredSMS:
          setOtpError(result.data?.message || t`Invalid code`);
          break;
        case AuthStatus.loggedIn:
          navigate(ROUTES.mobilePrivate.dashboard);
          break;
        default:
          break;
      }
    },
  });

  const getUserInfoQuery = useQuery(['get-user-info'], () => $vm.getUserInfo(), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    retry: false,
    onSuccess: (userDetails) => {
      if (!userDetails) return;
      if (
        [
          UserStatus.PreRegistered,
          UserStatus.PinAdded,
          UserStatus.PhoneNumberAdded,
          UserStatus.TypeOfAccountSet,
          UserStatus.DocumentsApproved,
          UserStatus.SystemNameAdded,
        ].includes(userDetails.asJson.userStatus as UserStatus)
      ) {
        navigate(ROUTES.onboarding);
      } else {
        navigate(ROUTES.mobilePrivate.dashboard);
      }
    },
    onError: () => {},
  });

  const resetValidation = () => {
    setLoginError('');
    loginMutation.reset();
  };

  const onLoginFormSubmit = (email: string, password: string) => {
    setEmail(email);
    setPassword(password);
    loginMutation.mutate({ email, password });
  };

  const onOtpSubmit = (code: string) => {
    sendCodeMutation.mutate({ code });
  };

  const onResendCode = () => {
    onLoginFormSubmit(email, password);
  };

  const handleDisableOtp = () => {
    setActiveForm(LoginFormEnum.CREDENTIALS);
  };

  const isLoading = () => {
    return loginMutation.isLoading || sendCodeMutation.isLoading || getUserInfoQuery.isFetching;
  };

  const loginForm = (
    <LoginForm
      isLoading={isLoading()}
      onSubmit={onLoginFormSubmit}
      error={loginError}
      defaultEmail={email}
      defaultPassword={password}
      resetValidation={resetValidation}
      isAvailableRegistration={$vm.isAvailableRegistration}
    />
  );

  const otpForm = (
    <OtpView
      isLoading={isLoading()}
      title={t`Enter code`}
      subTitle={t`Please verify with the 6-digits code sent you to phone`}
      email={''}
      timerDate={lastLoginTryDate as Date}
      timerDelayMinutes={$vm.mfaDelayMinutes}
      error={otpError}
      onResend={onResendCode}
      onBack={handleDisableOtp}
      onSubmit={onOtpSubmit}
    />
  );

  let formComponent = <></>;
  switch (activeForm) {
    case LoginFormEnum.CREDENTIALS:
      formComponent = loginForm;
      break;
    case LoginFormEnum.OTP:
      formComponent = otpForm;
      break;
    default:
      formComponent = <></>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.form}>{formComponent}</div>
    </div>
  );
};

export default appWithStyles(styles)(LoginComponent);
