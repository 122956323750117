import React, { useEffect, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Button } from '@mui/material';
import { Loading } from '@shared/components/loading';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { AuthSessionModel } from '@shared/models/users/auth-session-model';
import { AuthorizationRequestModel } from '@shared/models/users/authorization-request-model';
import { useMutation, useQuery } from 'react-query';

import QRCode from './components/qr-code/qr-code';
import { LoginWithQRCodeViewModel } from './login-with-qr-code.vm';

import { styles } from './login-with-qr-code.styles';

export interface LoginWithQRCodeProps extends AppWithStyles<typeof styles> {}

const LoginWithQRCodeComponent: React.FC<LoginWithQRCodeProps> = ({ classes }) => {
  const $vm = React.useMemo(() => new LoginWithQRCodeViewModel(), []);
  const [refetchInterval, setRefetchInterval] = React.useState<number | false>(3000);
  const navigate = useNavigate();

  const [currentAuthorizationRequest, setCurrentAuthorizationRequest] =
    useState<AuthorizationRequestModel | null>(null);

  const сreateAuthorizationRequestMutation = useMutation(() => $vm.сreateAuthorizationRequest(), {
    onSuccess: (data: AuthorizationRequestModel) => {
      setCurrentAuthorizationRequest(data);
    },
  });

  useEffect(() => {
    сreateAuthorizationRequestMutation.mutate();
  }, []);

  useQuery(
    ['get-authorization-request'],
    () => $vm.getAuthorizationRequest(currentAuthorizationRequest?.asJson.deviceCode),
    {
      refetchInterval: !currentAuthorizationRequest?.isExpired && refetchInterval,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      onSuccess: (authSession) => {
        if (authSession) {
          setRefetchInterval(false);
          authorizeUserMutation.mutate({ session: authSession as AuthSessionModel });
        }
      },
    },
  );

  const authorizeUserMutation = useMutation(
    (data: { session: AuthSessionModel }) => $vm.authorizeUser(data.session),
    {
      onSuccess: () => {
        navigate(ROUTES.mobilePrivate.dashboard);
      },
    },
  );

  const handleRegenerate = () => {
    сreateAuthorizationRequestMutation.mutate();
  };

  if (сreateAuthorizationRequestMutation.isLoading || authorizeUserMutation.isLoading) {
    return <Loading size={70} />;
  }

  const regenerateButton = !currentAuthorizationRequest?.isExpired ? (
    <></>
  ) : (
    <Button variant="contained" className={classes.regenarateButton} onClick={handleRegenerate}>
      {t`Regenerate`}
    </Button>
  );

  return (
    <div className={classes.root}>
      <div className={classes.title}>{t`Login With QR code`}</div>
      <div className={classes.description}>
        {t`Scan QR code with`} <span className={classes.app}>{t`app`}</span>
      </div>
      <div className={classes.qrCode}>
        <QRCode
          isActive={!currentAuthorizationRequest?.isExpired}
          data={currentAuthorizationRequest?.asJson.userCode as string}
        />
        {regenerateButton}
      </div>
    </div>
  );
};

export default appWithStyles(styles)(LoginWithQRCodeComponent);
