import React, { KeyboardEvent } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { InputText } from '@shared/components/new-design/input-text';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';

import { RecoveryEmailFieldsResolver, IRecoveryEmailForm } from './email.validator';

import { styles } from './email.styles';

export interface RecoveryEmailFormProps extends AppWithStyles<typeof styles> {
  onSubmit: (email: string) => void;
  resetValidation?: () => void;
  defaultValue: string;
  error?: string;
  isLoading?: boolean;
}

const RecoveryEmailFormComponent: React.FC<RecoveryEmailFormProps> = ({
  classes,
  error,
  onSubmit,
  resetValidation,
  defaultValue,
  isLoading,
}) => {
  const {
    setError,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<IRecoveryEmailForm>({ resolver: RecoveryEmailFieldsResolver });

  const onSubmitFrom = ({ email }: IRecoveryEmailForm) => {
    onSubmit(email);
  };

  const resetValidationFunc = () => {
    setError(nameOf<IRecoveryEmailForm>('email'), { message: '' });
    resetValidation && resetValidation();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter' && isValid && !Boolean(error)) {
      handleSubmit(onSubmitFrom)();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFrom)} onKeyDown={handleKeyPress} className={classes.root}>
      <div>
        <div className={classes.title}>{t`Password Recovery`}</div>
        <div
          className={classes.description}
        >{t`Please enter your email to get a code for recovery`}</div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <InputText
              controls={register(nameOf<IRecoveryEmailForm>('email'))}
              placeholder={t`Email`}
              resetValidation={resetValidationFunc}
              autoFocus
              defaultValue={defaultValue}
              error={Boolean(error)}
              helperText={error}
              key={'email'}
            />
          </div>
        </div>
      </div>
      <SubmitButton
        label={t`Send me a code`}
        disabled={!isValid || Boolean(error)}
        isLoading={isLoading}
        onSubmit={handleSubmit(onSubmitFrom)}
      />
    </form>
  );
};

export default appWithStyles(styles)(RecoveryEmailFormComponent);
