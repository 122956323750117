import React, { useEffect, useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { OtpView } from '@shared/components/new-design/otp-view';
import { useNavigate } from '@shared/components/router';
import { AuthStatus } from '@shared/constants/auth';
import { ROUTES } from '@shared/constants/routes';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { AuthStatusData } from '@shared/types/auth-service';
import { useMutation, useQuery } from 'react-query';

import EmailForm from './forms/email/email';
import PasswordForm from './forms/password/password';
import { RegisterViewModel } from './register.vm';

import { styles } from './register.styles';

enum RegisterFormEnum {
  EMAIL = 0,
  PASSWORD = 1,
  OTP = 2,
}

export interface RegisterProps extends AppWithStyles<typeof styles> {}

const RegisterComponent: React.FC<RegisterProps> = ({ classes }) => {
  const [activeForm, setActiveForm] = useState<RegisterFormEnum>(RegisterFormEnum.EMAIL);

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isEmailExistsError, setIsEmailExistsError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [registerError, setRegisterError] = useState<string>('');
  const [sendedFormData, setSendedFormData] = useState<{ email: string; password: string }>({
    email,
    password,
  });
  const [lastRegistrationTryDate, setLastRegistrationTryDate] = useState<Date>(new Date());
  const [otpError, setOtpError] = useState<string>('');

  const $vm = React.useMemo(() => new RegisterViewModel(), []);

  const isEmailExistsQuery = useQuery(['is-email-exists', email], () => $vm.isEmailExists(email), {
    onSuccess: (isExists) => {
      if (!email) return;
      if (isExists) {
        setIsEmailExistsError(true);
      } else {
        setActiveForm(RegisterFormEnum.PASSWORD);
      }
    },
    onError: (error: HttpErrorResponse) => {
      setEmailError(error.findFirstErrorMessage());
    },
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const registerMutation = useMutation(
    (data: { email: string; password: string }) => $vm.register(data.email, data.password),
    {
      onSuccess: (result: AuthStatusData, params) => {
        switch (result.status) {
          case AuthStatus.loggedOut:
            setRegisterError(result?.data?.message || t`Unknown error`);
            break;
          case AuthStatus.registeredUnconfirmed:
            setActiveForm(RegisterFormEnum.OTP);
            setSendedFormData(params);
            setLastRegistrationTryDate(new Date());
            break;
          default:
            break;
        }
      },
    },
  );

  const sendCodeMutation = useMutation((data: { code: string }) => $vm.sendCode(email, data.code), {
    onSuccess: (result: AuthStatusData) => {
      switch (result.status) {
        case AuthStatus.loggedIn:
          navigate(ROUTES.onboarding);
          break;
        case AuthStatus.registeredUnconfirmed:
          setOtpError(t`Please enter the valid code`);
          break;
        default:
          break;
      }
    },
  });

  const resendCodeMutation = useMutation(() => $vm.resendCode(email), {
    onSuccess: (result: boolean) => {
      if (!result) {
        setOtpError(t`Something went wrong`);
      } else {
        setLastRegistrationTryDate(new Date());
      }
    },
  });

  const handleResendOtp = () => {
    setOtpError('');
    resendCodeMutation.mutate();
  };

  const handleOtpSubmit = (code: string) => {
    sendCodeMutation.mutate({ code });
  };

  const handleDisableOtp = () => {
    setActiveForm(RegisterFormEnum.PASSWORD);
  };

  const onSubmitEmailForm = (email: string) => {
    setEmail(email);
    isEmailExistsQuery.refetch();
  };

  const onSubmitPassword = (newPassword: string) => {
    if (sendedFormData.email !== email || sendedFormData.password !== newPassword) {
      setPassword(newPassword);
      registerMutation.mutate({ email, password: newPassword });
    } else {
      setActiveForm(RegisterFormEnum.OTP);
    }
  };

  const navigate = useNavigate();

  const resetValidationEmail = () => {
    setEmailError('');
    setIsEmailExistsError(false);
  };

  useEffect(() => {
    if (location.pathname === ROUTES.public.root) {
      navigate(ROUTES.public.login());
    }
  }, []);

  const handleBackButton = () => {
    if (activeForm == 0) return;
    setActiveForm(activeForm - 1);
  };

  const isLoading = () => {
    return (
      isEmailExistsQuery.isFetching ||
      registerMutation.isLoading ||
      sendCodeMutation.isLoading ||
      resendCodeMutation.isLoading
    );
  };

  const emailForm = (
    <EmailForm
      onSubmit={onSubmitEmailForm}
      error={emailError}
      defaultValue={email}
      resetValidation={resetValidationEmail}
      isEmailExistsError={isEmailExistsError}
      isLoading={isLoading()}
      isAvailableRegistration={$vm.isAvailableRegistration}
    />
  );

  const passwordForm = (
    <PasswordForm
      onSubmit={onSubmitPassword}
      error={registerError}
      defaultValue={password}
      resetValidation={resetValidationEmail}
      isLoading={isLoading()}
      isAvailableRegistration={$vm.isAvailableRegistration}
    />
  );

  const otpForm = (
    <OtpView
      title={t`Enter code`}
      subTitle={t`Please verify with the 6-digits code sent you to`}
      email={email}
      timerDate={lastRegistrationTryDate}
      timerDelayMinutes={$vm.mfaDelayMinutes}
      error={otpError}
      onResend={handleResendOtp}
      onBack={handleDisableOtp}
      onSubmit={handleOtpSubmit}
      isLoading={isLoading()}
    />
  );

  let formComponent = <></>;
  switch (activeForm) {
    case RegisterFormEnum.EMAIL:
      formComponent = emailForm;
      break;
    case RegisterFormEnum.PASSWORD:
      formComponent = passwordForm;
      break;
    case RegisterFormEnum.OTP:
      formComponent = otpForm;
      break;
    default:
      formComponent = <></>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.backButton}>
        {[RegisterFormEnum.OTP, RegisterFormEnum.PASSWORD].includes(activeForm) ? (
          <BackButton onClick={handleBackButton} />
        ) : (
          <></>
        )}
      </div>
      <div className={classes.form}>{formComponent}</div>
    </div>
  );
};

export default appWithStyles(styles)(RegisterComponent);
