import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      width: '100%',
      height: _var(CSS_VARIABLES.appHeight),
      display: 'flex',
      fontFamily: _var(CSS_VARIABLES.fontFamily),
      color: '#fff',
    },
    empty: {},
    presentation: {
      width: '50%',
      backgroundColor: _var(CSS_VARIABLES.colorPrimary500),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '0%',
        display: 'none',
      },
      '$empty &': {
        width: '0%',
        display: 'none',
      },
    },
    content: {
      height: '100%',
      width: '50%',
      background: 'linear-gradient(180deg, #1B1629 0%, #000 100%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down(Layout.tablet)]: {
        width: '100%',
      },
      '$empty &': {
        width: '100%',
      },
    },
  });
}
