import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';
export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '100%',
      width: 'auto',
      padding: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(Layout.tablet)]: {
        padding: '20px',
        justifyContent: 'space-between',
      },
    },
    title: {
      fontWeight: 500,
      textAlign: 'center',
      fontSize: '24px',
      marginBottom: '11px',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
    },
    description: {
      fontWeight: 400,
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '20px',
    },
    app: {
      color: _var(CSS_VARIABLES.colorSecondary100),
      fontWeight: 700,
    },
    qrCode: {
      marginTop: '30px',
      position: 'relative',
      width: 'fit-content',
    },
    regenarateButton: {
      height: '56px',
      width: '100%',
      position: 'absolute',
      top: '69px',
    },
  });
}
