import React, { useEffect, useRef } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import QRCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
} from 'qr-code-styling';

import { styles } from './qr-code.styles';

export interface LoginFormProps extends AppWithStyles<typeof styles> {
  data: string;
  isActive: boolean;
}

const qrCode = new QRCodeStyling({
  width: 191,
  height: 191,
  type: 'svg' as DrawType,
  image:
    'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjIiIGhlaWdodD0iNjEiIHZpZXdCb3g9IjAgMCA2MiA2MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTM3LjUyOTggNy42NzA5SDI0LjMxNTNDMTUuMzk1MyA3LjY3MDkgOC4xNjQyNSAxNC45MDIgOC4xNjQyNSAyMy44MjJWMzcuMDM2NUM4LjE2NDI1IDQ1Ljk1NjUgMTUuMzk1MyA1My4xODc2IDI0LjMxNTMgNTMuMTg3NkgzNy41Mjk4QzQ2LjQ0OTggNTMuMTg3NiA1My42ODA5IDQ1Ljk1NjUgNTMuNjgwOSAzNy4wMzY1VjIzLjgyMkM1My42ODA5IDE0LjkwMiA0Ni40NDk4IDcuNjcwOSAzNy41Mjk4IDcuNjcwOVoiIGZpbGw9IiM5NjcwRkYiLz4KPHBhdGggZD0iTTM3LjUzMDYgNEgyNC4zMTYxQzEzLjM2ODggNCA0LjQ5NDMyIDEyLjg3NDUgNC40OTQzMiAyMy44MjE4VjM3LjAzNjNDNC40OTQzMiA0Ny45ODM1IDEzLjM2ODggNTYuODU4MSAyNC4zMTYxIDU2Ljg1ODFIMzcuNTMwNkM0OC40Nzc5IDU2Ljg1ODEgNTcuMzUyNCA0Ny45ODM1IDU3LjM1MjQgMzcuMDM2M1YyMy44MjE4QzU3LjM1MjQgMTIuODc0NSA0OC40Nzc5IDQgMzcuNTMwNiA0WiIgc3Ryb2tlPSIjMUUyOTRBIiBzdHJva2Utd2lkdGg9IjcuMzQxNCIvPgo8cGF0aCBkPSJNMjguMDc0MiA0MS40OTE5TDE0IDI3LjQxNzZMMjAuNDAyMiAyMS4wMTU0TDI1LjMwNTMgMjUuOTE4NEwyMy44MDYxIDI3LjQxNzZMMzIuOTc3MyAzNi41ODg4TDI4LjA3NDIgNDEuNDkxOVoiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik00My4wOTcgMzUuNDg3M0wzMy41MjA0IDI1LjkxMDdMMzguNDMxIDIxLjAyMjlMNDggMzAuNTkxOUw0My4wOTcgMzUuNDg3M1oiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zNy4wODQ4IDQxLjQ5MThMMzIuMTgxNyAzNi41ODg3TDMzLjEzNzggMzUuNjMyNkMzMy4yODMyIDM1LjQ4NzMgMzMuMzY3MyAzNS4yODg0IDMzLjM2NzMgMzUuMDgxOUMzMy4zNjczIDM0Ljg3NTMgMzMuMjgzMiAzNC42NzY1IDMzLjEzNzggMzQuNTMxMUwyNC41MDk3IDI1LjkwM0wyOS40MTI4IDIxTDM0LjMwODIgMjUuODk1NEwzMy4zNTIgMjYuODUxNUMzMy4yMDY3IDI2Ljk5NjggMzMuMTIyNiAyNy4xOTU3IDMzLjEyMjYgMjcuNDAyMkMzMy4xMjI2IDI3LjYwODggMzMuMjA2NyAyNy44MDc2IDMzLjM1MiAyNy45NTNMNDEuOTg3OCAzNi41ODg3TDM3LjA4NDggNDEuNDkxOFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=',
  margin: 5,
  qrOptions: {
    typeNumber: 0 as TypeNumber,
    mode: 'Byte' as Mode,
    errorCorrectionLevel: 'Q' as ErrorCorrectionLevel,
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.6,
    margin: 0,
    crossOrigin: 'anonymous',
  },
  dotsOptions: {
    type: 'square' as DotType,
  },
  cornersSquareOptions: {
    type: 'extra-rounded' as CornerSquareType,
  },
  cornersDotOptions: {
    type: 'square' as CornerDotType,
  },
});

const QRCodeComponent: React.FC<LoginFormProps> = ({ classes, data, isActive }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    qrCode.update({ data });
  }, [data]);

  return (
    <div className={isActive ? classes.root : `${classes.root} ${classes.blur}`}>
      <div ref={ref} />
    </div>
  );
};

export default appWithStyles(styles)(QRCodeComponent);
