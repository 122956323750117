import { appInject } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { IAuthService } from '@shared/types/auth-service';
import { IConfigService } from '@shared/types/config-service';
import { IUsersService } from '@shared/types/users-service';

export class RegisterViewModel {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private configService = appInject<IConfigService>(DI_TOKENS.configService);

  constructor() {}

  get isAvailableRegistration() {
    return this.configService.isAvailableRegistration;
  }

  get mfaDelayMinutes() {
    return this.configService.mfaDelayMinutes;
  }

  register = async (email: string, password: string) => {
    return await this.authService.register(email, password);
  };

  isEmailExists = async (email: string): Promise<boolean> => {
    if (!email) return false;
    return await this.usersService.isEmailExists(email);
  };

  resendCode = async (email: string) => {
    return await this.authService.resendEmailConfirmation(email);
  };

  sendCode = async (email: string, code: string) => {
    return await this.authService.confirmEmail(email, code);
  };
}
