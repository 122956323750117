import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { t } from '@lingui/macro';
import { IsEmail, MinLength } from 'class-validator';

export interface IRegisterEmailForm {
  email: string;
}

export class RegisterEmailFields implements IRegisterEmailForm {
  @MinLength(1, { message: t`This field is mandatory` })
  @IsEmail({}, { message: t`Wrong email format` })
  email: string;
}

export const RegisterEmailFieldsResolver = classValidatorResolver(RegisterEmailFields);
