import React, { useEffect } from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { OnboardingCarousel } from '@modules/private/onboarding/components/carousel';
import { Outlet, useNavigate } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { ROUTES } from '@shared/constants/routes';
import { IAuthService } from '@shared/types/auth-service';

import LoginWithQRCode from '../auth/login-with-qr-code/login-with-qr-code';

import { styles } from './layout.styles';

export interface PublicLayoutProps extends AppWithStyles<typeof styles> {}

const Layout: React.FC<PublicLayoutProps> = ({ classes }) => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === ROUTES.public.root) {
      navigate(ROUTES.public.login());
    }
  }, []);

  const loginWithQRCodeComponent = authService.isLoggedIn ? (
    <OnboardingCarousel />
  ) : (
    <LoginWithQRCode />
  );

  const presentationComponent = loginWithQRCodeComponent;

  const rootClass = Boolean(presentationComponent)
    ? classes.root
    : `${classes.root} ${classes.empty}`;

  return (
    <div className={rootClass}>
      <div className={classes.presentation}>{loginWithQRCodeComponent}</div>
      <div className={classes.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default appWithStyles(styles)(Layout);
