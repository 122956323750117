import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';
export function styles() {
  return appCreateStyles({
    root: {
      padding: '20px',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    title: {
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
    },
    imageBlock: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 'calc(100vh - 350px)',
    },
    image: {
      width: '217px',
    },
    info: {
      background: '#231010;',
      border: '1px solid #B42626',
      borderRadius: '12px',
      width: '100%',
      padding: '16px',
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
      marginBottom: '30px',
      '& p': {
        marginBottom: '0px',
      },
      '& p + p': {
        marginTop: '10px',
      },
    },
    description: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      lineHeight: '17px',
      letterSpacing: '0.2px',
      padding: '10px',
      marginBottom: '20px',
    },
    buttonBlock: {
      paddingTop: '20px',
      background: 'transparent',
    },
  });
}
